<template>
  <div class="priv mx-5">
    <hero-alt />
    <base-section id="theme-features">
    <base-section-heading title="Privacidad">
      
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col>
          <div class="content">
            <p>
                <strong>Monitor Satelital</strong> como parte de la actividad normal del sistema se recauda cierta información del sistema.<br>
                La recogida de datos se realiza para mejorar el servicio que ofrecemos a todos nuestros usuarios asi como para poder estar en contacto. Toda información recaudada es almacenada con normas de seguridad y garantizamos que los datos no son transmitidos a ningun otro sistema.
            </p>
            <p>
                </p><h3>Datos sobre el dispositivo</h3>
                Podremos recoger datos específicos sobre tu dispositivo (como, por ejemplo, el modelo de equipo, la versión del sistema, los identificadores únicos y los datos sobre la red móvil, incluyendo el número de teléfono). Esto con el fin de identificar y poder tener interacción con el dispositivo GPS y garantizar que solo un usuario este dado de alta con el mismo ID de dispositivo.
            <p></p>
            <p>
                </p><h3>Datos sobre el ubicación</h3>
                Los datos actuales e historicos guardados en el sistema son unicamente visibles a aquellas personas y/o cuentas que el mismo usuario dio permiso para poder revisar los datos. El uso es exclusivamente confidencial y para fines informativos.
            <p></p>
        </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>


  <base-business-info>
    
  </base-business-info>

  </div>
</template>
<script>

export default {
  components: {
    HeroAlt: () => import('./sections/HeroAlt'),
  },
  metaInfo: { title: 'Privacidad' },
  name: "Privacidad",
}
</script>
<style>
</style>

